<template>
  <painel-component :itens="itensOne">
    <row-component>
      <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <label>Nome:</label>
        <input-component :type-input="'text'" v-model="formData.name" />
      </row-col-component>

      <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <label>Status:</label>
        <Multiselect
          v-model="formData.status"
          :options="selectActiveInative"
          :searchable="true"
          :noOptionsText="noOptionsText"
          :noResultsText="noResultsText"
        />
      </row-col-component>

      <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <label>CPF:</label>
        <input-component v-mask="'###.###.###-##'" :type-input="'text'" v-model="formData.cpf" />
      </row-col-component>

      <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
        <button-submit @submit="submit" :items="buttonSubmit" class="me-2" />
        <button-route v-if="buttonCheck.create" :items="buttonRoute" />
      </row-col-component>
    </row-component>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" style="width: 20%">Nome</th>
          <th scope="col" style="width: 20%">Status</th>
          <th scope="col" style="width: 20%">CPF</th>
          <th scope="col" style="width: 20%">RG</th>
          <th scope="col" style="width: 10%" v-if="buttonCheck.show || buttonCheck.edit || buttonCheck.delete">
            Ações
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(employee, index) in employee.data" :key="index">
          <td data-label="Nome">
            <img v-if="employee.photo" class="avatar__image mx-1" :src="employee.photo" alt="" />
            <img v-else class="avatar__image mx-1" src="@/assets/images/avatar.png" alt="" />
            {{ employee.name }}
          </td>
          <td data-label="Status">{{ statusString(employee.status) }}</td>
          <td data-label="CPF">{{ employee.cpf }}</td>
          <td data-label="CPF">{{ employee.rg }}</td>
          <td data-label="Ações">
            <button-edit v-if="buttonCheck.edit" :route="'employee-edit'" :id-param="employee.id" />
            <button-delete v-if="buttonCheck.delete" @click="destroyEmployee(employee)" />
            <button-show v-if="buttonCheck.show" :route="'employee-show'" :id-param="employee.id" />
          </td>
        </tr>
      </tbody>
    </table>

    <button-pagination :pagination="employee" :offset="3" @paginate="loadEmployees" />
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import ButtonShow from '@/components/buttons/ButtonShow';
import ButtonDelete from '@/components/buttons/ButtonDelete.vue';
import { useToast } from 'vue-toastification';
import { mask } from 'vue-the-mask';
import ButtonPagination from '@/components/buttons/ButtonPagination.vue';

import swal from 'sweetalert';

const toast = useToast();

export default {
  name: 'EmployeeSearch',

  created() {
    this.$store.dispatch('updateNamePage', 'Funcionário');
    this.checkACL();
    this.loadEmployees(1);
  },

  components: {
    ButtonDelete,
    ButtonPagination,
    PainelComponent,
    RowComponent,
    RowColComponent,
    InputComponent,
    Multiselect,
    ButtonSubmit,
    ButtonRoute,
    ButtonEdit,
    ButtonShow
  },

  directives: {
    mask
  },

  data() {
    return {
      itensOne: {
        title: 'Pesquisa',
        icon: 'ti-list'
      },

      buttonSubmit: {
        icon: 'ti-search'
      },

      buttonRoute: {
        icon: 'ti-plus',
        route: '/employee/create'
      },

      buttonCheck: {
        edit: false,
        create: false,
        show: false
      },

      formData: {
        name: '',
        cpf: '',
        status: ''
      },

      searchSubmit: false
    };
  },

  methods: {
    submit() {
      this.loadEmployees(1);
      this.searchSubmit = true;
    },

    statusString(value) {
      if (value === '1') return 'Ativo';
      return 'Inativo';
    },

    loadEmployees(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadEmployees', { ...this.params, page })
          .then(r => {
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.data == '') {
                toast.info('Nada foi encontrado com os parâmetros informados!', { timeout: 2000 });
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    destroyEmployee(item) {
      swal({
        title: 'Atenção!',
        text: `Deseja realmente deletar ${item.name} ?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true
          },
          confirm: 'Sim'
        }
      }).then(result => {
        if (result) {
          this.$store
            .dispatch('destroyEmployee', item.id)
            .then(() => {
              this.loadEmployees(1);
              toast.success('Funcionário deletado com sucesso', { timeout: 2000 });
            })
            .catch(e => {
              let error = e.response.data.errors;
              if (error.length > 0) toast.error(`Não foi possível excluir, o item está em uso!`, { timeout: 2000 });
              else toast.error(`Não foi possível deletar  ${item.name} `, { timeout: 2000 });
            });
        }
      });
    },

    async checkACL() {
      this.$store
        .dispatch('ACLitens', 8)
        .then(r => {
          if (r.data.indexOf('employee-search') !== -1) {
            if (r.data.indexOf('employee-search') !== -1) {
              this.buttonCheck.show = true;
            }

            if (r.data.indexOf('employee-create') !== -1) {
              this.buttonCheck.create = true;
            }

            if (r.data.indexOf('employee-edit') !== -1) {
              this.buttonCheck.edit = true;
            }
            if (r.data.indexOf('employee-delete') !== -1) {
              this.buttonCheck.delete = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    }
  },

  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative;
    },

    noResultsText() {
      return this.$store.state.var.noResultsText;
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText;
    },

    employee() {
      return this.$store.state.employee.items;
    },

    params() {
      return {
        page: this.employee.current_page,
        name: this.formData.name,
        cpf: this.formData.cpf,
        status: this.formData.status
      };
    }
  }
};
</script>

<style src="@/assets/style/table.css"></style>

<style scoped>
.avatar__image {
  width: 25px;
}
</style>
