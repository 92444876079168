<template>
  <painel-component :itens="itensOne">
    <row-component>
      <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
        <label>Nome:</label>
        <input-component :type-input="'text'" v-model="formData.name" />
      </row-col-component>

      <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
        <label>Status:</label>
        <Multiselect
          v-model="formData.status"
          :options="selectActiveInative"
          :searchable="true"
          :noOptionsText="noOptionsText"
          :noResultsText="noResultsText"
        />
      </row-col-component>

      <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4'">
        <button-submit @submit="submit" :items="buttonSubmit" class="me-2" />
        <button-route v-if="buttonCheck.create" :items="buttonRoute" />
      </row-col-component>
    </row-component>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" style="width: 35%">Nome</th>
          <th scope="col" style="width: 35%">Status</th>
          <th scope="col" style="width: 10%" v-if="buttonCheck.show || buttonCheck.edit || buttonCheck.delete">
            Ações
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(category, index) in category.data" :key="index">
          <td data-label="Nome">{{ category.name }}</td>
          <td data-label="Status">{{ statusString(category.status) }}</td>
          <td data-label="Ações" v-if="buttonCheck.show || buttonCheck.edit || buttonCheck.delete">
            <button-edit v-if="buttonCheck.edit" :route="'category-edit'" :id-param="category.id" />
            <button-delete v-if="buttonCheck.delete" @click="destroy(category)" />
            <button-show v-if="buttonCheck.show" :route="'category-show'" :id-param="category.id" />
          </td>
        </tr>
      </tbody>
    </table>

    <button-pagination :pagination="category" :offset="3" @paginate="loadCategories" />
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import ButtonShow from '@/components/buttons/ButtonShow';
import { useToast } from 'vue-toastification';
import ButtonPagination from '@/components/buttons/ButtonPagination';
import ButtonDelete from '@/components/buttons/ButtonDelete';
import swal from 'sweetalert';

const toast = useToast();

export default {
  name: 'CategorySearch',

  created() {
    this.$store.dispatch('updateNamePage', 'Categoria');
    this.checkACL();
    this.loadCategories(1);
  },

  components: {
    ButtonDelete,
    ButtonPagination,
    PainelComponent,
    RowComponent,
    RowColComponent,
    InputComponent,
    Multiselect,
    ButtonSubmit,
    ButtonRoute,
    ButtonEdit,
    ButtonShow
  },

  data() {
    return {
      itensOne: {
        title: 'Pesquisa',
        icon: 'ti-list'
      },

      buttonSubmit: {
        icon: 'ti-search'
      },

      buttonRoute: {
        icon: 'ti-plus',
        route: '/category/create'
      },

      buttonCheck: {
        edit: false,
        create: false,
        show: false,
        delete: false
      },

      formData: {
        name: '',
        status: ''
      },

      searchSubmit: false
    };
  },

  methods: {
    submit() {
      this.loadCategories(1);
      this.searchSubmit = true;
    },

    statusString(value) {
      if (value === '1') return 'Ativo';
      return 'Inativo';
    },

    loadCategories(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadCategories', { ...this.params, page })
          .then(r => {
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.data == '') {
                toast.info('Nada foi encontrado com os parâmetros informados!', { timeout: 2000 });
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    async checkACL() {
      this.$store
        .dispatch('ACLitens', 6)
        .then(r => {
          if (r.data.indexOf('category-search') !== -1) {
            if (r.data.indexOf('category-search') !== -1) {
              this.buttonCheck.show = true;
            }

            if (r.data.indexOf('category-create') !== -1) {
              this.buttonCheck.create = true;
            }

            if (r.data.indexOf('category-edit') !== -1) {
              this.buttonCheck.edit = true;
            }

            if (r.data.indexOf('category-delete') !== -1) {
              this.buttonCheck.delete = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    destroy(item) {
      swal({
        title: 'Atenção!',
        text: `Deseja realmente deletar ${item.name}, ${this.me.name} ?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true
          },
          confirm: 'Sim'
        }
      }).then(result => {
        if (result) {
          this.$store
            .dispatch('destroyCategory', item.id)
            .then(() => {
              this.loadCategories(1);
              toast.success('Cadastro deletado com sucesso', { timeout: 2000 });
            })
            .catch(r => {
              let error = r.response.data.errors[0].toString();
              toast.warning(`${error}`, { timeout: 2000 });
            });
        }
      });
    }
  },

  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative;
    },

    noResultsText() {
      return this.$store.state.var.noResultsText;
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText;
    },

    category() {
      return this.$store.state.category.items;
    },

    params() {
      return {
        page: this.category.current_page,
        name: this.formData.name,
        status: this.formData.status
      };
    },

    me() {
      return this.$store.state.auth.me;
    }
  }
};
</script>

<style src="@/assets/style/table.css"></style>
